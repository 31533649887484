import React, { useState, useRef } from "react";
import styles from './Footer.module.css';
import footer from '../../assets/img/Background_04.png';
import footerN from '../../assets/img/FooterBGN.png';
import { ButtonA } from '../../components/ButtonA/ButtonA';
import FlechaW from '../../assets/img/web/flechaW.png';
import emailjs from "@emailjs/browser";

function Footer({ black }) {
  
  const [activeForm, setActiveForm] = useState(false);
  const [completedForm, setCompletedForm] = useState(false);
  const form = useRef(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
    .sendForm('service_q7b386u', 'template_hqm0154', form.current, {
      publicKey: '-N1_TJeOHonbo-nAd',
    })
    .then(
      (result) => {
        console.log(result.text);
        console.log('success');
        setCompletedForm(true);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.botones} onClick={() => setActiveForm(true)}>
        <ButtonA inverted={ black ? true : false } pad='17px 2%'/> 
        <ButtonA inverted={ black ? true : false } icon={FlechaW} pad='0% 2%'/> 
        <ButtonA inverted={ black ? true : false } text='CONTÁCTANOS' pad='0.2% 10%'/>
        <ButtonA inverted={ black ? true : false } icon={FlechaW} pad='0% 2%'/> 
        <ButtonA inverted={ black ? true : false } pad='17px 2%'/>
      </div>
      <img src={ black ? footerN : footer} alt="footer" className={styles.imgFooter} />
      <div className={`contactFormContainer ${activeForm ? 'active' : ''}`}>
        {!completedForm && 
        <div className='formContainer'>
          <img className='logo' src='/img/logo2.svg'></img>
          <img className='closeIcon' src='/icon/icon.svg' alt='Deepia Logo' onClick={() => setActiveForm(false)}/>
          <p>Por favor, rellene el siguiente formulario y uno de nuestros comerciales se pondrá en contacto con usted lo antes posible</p>
          <form ref={form} onSubmit={sendEmail}>
              <input type='text' name='fromName' placeholder='Nombre Completo' className='doubleColumn' required/>
              <input type='text' name='phone' placeholder='TELÉFONO' required/>
              <input type='email' name='email' placeholder='Correo' required/>
              <input type='text' name='service' placeholder='Servicio' className='doubleColumn' required/>
              <input type='text' name='position' placeholder='Puesto' required/>
              <input type='text' name='company' placeholder='Compañia' required/>
              <input type='text' name='industry' placeholder='Industria de la empresa' required/>
              <input type='text' name='city' placeholder='Ciudad de su compañia' required/>
              <textarea name='message' placeholder='Detalles adicionales' className='doubleColumn' required></textarea>
              <br/>
              <button type='submit'>ENVIAR</button>
          </form>
        </div>}
        {completedForm && 
        <div className='formContainer'>
          <img className='logo' src='/img/logo2.svg'></img>
          <img className='closeIcon' src='/icon/icon.svg' alt='Deepia Logo' onClick={() => setActiveForm(false)}/>
          <h2>¡Gracias por tu mensaje!</h2>
        </div>}
      </div>
    </footer>
  );
}

export { Footer };