import React from "react";
import styles from './ButtonA.module.css';

function ButtonA({icon, pad, onClick, text, inverted}) {
    return (
        <button onClick={onClick} className={styles.contact} style={{padding:[pad], backgroundColor: inverted ? '#fff' : '#000', borderColor: inverted ? '#000' : '#fff', borderRadius: '999px'}}>
            <img className={styles.icon} src={icon} style={{ filter: inverted ? 'invert()' : 'invert(0)', width: '32px'}}></img>
            <p id={styles.buttonAText}>{text}</p>
        </button>
    );
}

export { ButtonA };