import { useRoutes, BrowserRouter} from 'react-router-dom';
import Home from '../Home/Home';
import Hardware from '../Hardware/Hardware';
import LinkedLead from '../LinkedLead/LinkedLead';
import WebApps from '../WebApps/WebApps';
import WorkVision from '../WorkVision/WorkVision';
import NavBar from '../../components/NavBar/NavBar';
import { Success } from '../Success/Success';

const AppRoutes = () => {
  let routes = useRoutes([
    { path: '/*', element: <Home /> },
    { path: '/hardware', element: <Hardware /> },
    { path: '/linkedlead', element: <LinkedLead /> },
    { path: '/success-cases', element: <Success /> },
    { path: '/web', element: <WebApps /> },
    { path: '/workvision', element: <WorkVision /> },
  ]);

  return routes
}

const App = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;