import React from "react";
import { useNavigate } from "react-router-dom";
import styles from './RoundedSlide.module.css';

function RoundedSlide(props) {
    const navigate = useNavigate();

    const navToPage = (url) => {
        console.log("Navigating to: " + url);
        navigate(url);
    }
    return (
        <div className={styles.roundedDiv} style={{ backgroundImage: `url('${props.imagepath}')`}} onClick={() => navToPage(props.nav)}></div>
    );
}

export { RoundedSlide };