import React, { useState } from "react";
import styles from './CardCarrusel.module.css';
import { Card } from '../Card/Card';
import { ButtonA } from '../ButtonA/ButtonA';
import FlechaD from '../../assets/img/workvision/rightArrow.webp';
import FlechaI from '../../assets/img/workvision/leftArrow.webp';

function CardCarrusel(props) {
    const Cards = props.Cards;
    const [n, setN] = useState(0);

    const nextSlide = () => {
        if (n < Cards.length - 1) {
            setN(n + 1);
            console.log('Next slide:', n + 1);
        } else {
            setN(0);
            console.log('Reached the last slide');
        }
        console.log('Card1: ' + n);
        console.log('Card2: ' + (n+1));
        console.log('Card3: ' + (n+2));
    }
    
    const prevSlide = () => {
        if (n > 0) {
            setN(n - 1);
            console.log('Previous slide:', n-1);
        } else {
            setN(Cards.length - 1);
            console.log('Reached the first slide');
        }
        console.log('Card1: ' + n);
        console.log('Card2: ' + (n+1));
        console.log('Card3: ' + (n+2));
    }
    const getCardIndex = (CardNumber) => {
        switch(CardNumber){
            case 2:
                console.log((n+1)+' ' + Cards.length);
                
                if ((n+1) >= Cards.length){
                    return ( n-Cards.length + 1);
                }
                else{
                    return (n+1);
                }
            default:
                console.log((n+2)+' ' + Cards.length);
                if ((n+2) >= Cards.length){
                    return ( n-Cards.length + 2);
                }
                else{
                    return (n+2);
                }
        }
    }
    
    return (
       <div className={styles.container}> 
            <div className={styles.carouselContainer}>
                <div className={styles.Card1}><Card CardImage={Cards[n].image} text="Adios" /></div>
                <div className={styles.Card2}><Card CardImage={Cards[getCardIndex(2)].image} text={Cards[getCardIndex(2)].text} /></div>
                <div className={styles.Card3}><Card CardImage={Cards[getCardIndex(3)].image} text="Adios" /></div>
            </div>
            <h1 className={styles.h1C}>CONTROLA</h1>
            <div className={styles.botonesContainer}>
                <ButtonA onClick={prevSlide} icon={FlechaI} pad='0% 2%' />
                <ButtonA onClick={nextSlide} icon={FlechaD} pad='0% 2%' />
            </div>
        </div>
    );
}

export { CardCarrusel };