import React from "react";
import styles from './Product.module.css';

function Product({title,paragraph}) {
    return (
        <div className={styles.container}>
          <h1>{title}</h1>
          <p>{paragraph}</p>
        </div>
    );
}

export { Product };