import React, { useState } from 'react';
import styles from '../WorkVision/WorkVision.module.css'; 
import { Footer } from '../../components/Footer/Footer';
import { Product } from '../../components/Product/Product';
import	{ CardCarrusel } from '../../components/CardCarrusel/CardCarrusel';
import { TextBigNumber } from '../../components/TextBigNumber/TextBigNumber';
import circle from '../../assets/img/workvision/Background_09.png';
import Card1 from '../../assets/img/workvision/abstract-form-illustration.png';
import Card2 from '../../assets/img/workvision/card2.png';
import Card3 from '../../assets/img/workvision/1894.png';
import Card4 from '../../assets/img/workvision/card4.png';
import Spline from '@splinetool/react-spline';
import NavBar from '../../components/NavBar/NavBar';

function WorkVision() {

  const Cards = [
    { id: 1, image: Card1, text: 'Análisis y control de gastos energéticos y merma de material, analisis preventivo para mantenimientos de maquinaria, detección de causa raíz de fallas de paro total de línea, Control real de...' },
    { id: 2, image: Card2, text: 'merma de material, analisis preventivo para mantenimientos de maquinaria, detección de causa raíz de fallas de paro total de línea, Control real de...' }, 
    { id: 3, image: Card3, text: 'preventivo para mantenimientos de maquinaria, detección de causa raíz de fallas de paro total de línea, Control real de...' },
    { id: 4, image: Card4, text: 'control de gastos energéticos mantenimientos de maquinaria, detección de causa raíz de fallas de paro total de línea, Control real de...' }
];

  return (
    <main className={styles.main}>
      <NavBar />
      <div className={styles.background} />
      <div className={styles.containerText}>
        <Spline scene="https://prod.spline.design/v5MRQ0D7gFi9XoTq/scene.splinecode" />
        <p>Desarrollamos herramientas con inteligencia artificial enfocadas <br/> a la automatización y mejora de las líneas de producción.</p>
      </div>
      <div className={styles.divContainer}>
        <img src={circle} alt='circle'/>
        <div className={styles.containerT}>
          <h1>REVOLUCIONANDO LA EFICIENCIA</h1>
          <div className={styles.containersubT}>
            <div className={styles.vSeparator}><div></div></div>
            <p>Una red de inteligencia artificial para las cadenas productivas, dota sustancial control a las líneas de producción formando un fuerte vínculo de control y calidad nivel laboratorio 1 a 1 con cada producto. Hardware dedicado de fácil y rápida implementación que funciona en todo tipo de maquinaria, incluso la más clásica.</p>
          </div>
        </div>      
      </div>
      <CardCarrusel Cards={Cards}/>
      <div className={styles.containerProduct}>
        <h1>APLICACIONES EN LA LINEA DE PRODUCCIÓN</h1>
        <p>Una red de inteligencia artificial para las cadenas productivas, dota sustancial control a las líneas de producción <br/> formando un fuerte vínculo de control y calidad nivel laboratorio 1 a 1 con cada producto.<br/><br/> Hardware dedicado de fácil y rápida implementación que funciona en todo tipo de maquinaria, incluso la más clásica.</p>
          <div className={styles.background1}>
            <div className={styles.shadow}></div>
            <div></div>
            <div><Product title='SENSORES' paragraph={(<>Conoce tu linea <br/> de producción</>)}/></div>
            <div></div>
            <div><Product title='ACTUADOR' paragraph={(<>Realiza órdenes <br/> en tiempo real</>)}/></div>
            <div></div>
            <div><Product title='ERP' paragraph={(<>Conecta tu ERP</>)}/></div>
            <div></div>
          </div>
      </div>
      <div className={styles.containerTech}>
        <h1>LA IMPLEMENTACIÓN<br/> DE NUESTRA TECNOLOGÍA</h1>
        <div className={styles.containerNum}>
          <TextBigNumber number='01' text='MANTIENE EL VALOR DE TU INVERSIÓN EN MAQUINARIA'/>
          <TextBigNumber number='02' text='MANTIENE EL VALOR DE TU INVERSIÓN EN MAQUINARIA'/>
          <TextBigNumber number='03' text='MANTIENE EL VALOR DE TU INVERSIÓN EN MAQUINARIA'/>
        </div>
      </div>
      <Footer/>
    </main>
  );
}

export default WorkVision;