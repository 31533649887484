import React from "react";
import styles from './Card.module.css';

function Card(props) {
    return (
        <div className={props.active ? `${styles.Card} ${styles.active}` : styles.Card} style={{ backgroundImage: `url(${props.CardImage})`}}>
           {/* <img src={props.CardImage} alt="Card"/> */}
           <p>{props.text}</p>
        </div>
    );
}

export { Card };