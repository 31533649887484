import React from "react";
import styles from "./HSeparator.module.css";

const HSeparator = (props) => {
    return (
        <div className={styles.hSeparator} style={{ borderColor: props.color ? 'black' : '' }}>
            <div style={{ background: props.color ? 'black' : '' }}></div>
        </div>
    );
}

export { HSeparator };