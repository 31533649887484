import React, { useEffect, useRef } from "react";
import styles from "./Success.module.css";
import Slider3 from "../../components/Slider3/Slider3";
import { RoundedSlide } from "../../components/Slider3Components/RoundedSlide/RoundedSlide";
import NavBar from "../../components/NavBar/NavBar";
import {VSeparator} from "../../components/VSeparator/VSeparator";

const Success = () => {
    const dpSectionRef = useRef(null);
    const ecmSectionRef = useRef(null);
    const nemuSectionRef = useRef(null);
    const mageovaSectionRef = useRef(null);
    const qebSectionRef = useRef(null);
    const xzealSectionRef = useRef(null);

    useEffect(() => {
        const url = window.location.href;
        const section = url.split("#")[1];
        if (section === "dagpacket") {
            dpSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (section === "ecm") {
            ecmSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (section === "nemu") {
            nemuSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (section === "mageova") {
            mageovaSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (section === "qeb") {
            qebSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (section === "xzeal") {
            xzealSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    })

    return (
        <main id={styles.success}>
            <NavBar black/>
            <section className={styles.section1}>
                <h2>TRANSFORMAMOS RETOS EN ÉXITOS</h2>
                <Slider3>
                    <RoundedSlide imagepath='/img/clientes/dagpacket/logo.png' nav='/success-cases/#dagpacket'></RoundedSlide>
                    <RoundedSlide imagepath='/img/clientes/ecm/logo.png' nav='/success-cases/#ecm'></RoundedSlide>
                    <RoundedSlide imagepath='/img/clientes/nemu/logo.webp' nav='/success-cases/#nemu'></RoundedSlide>
                    <RoundedSlide imagepath='/img/clientes/mageova/logo.webp' nav='/success-cases/#mageova'></RoundedSlide>
                    <RoundedSlide imagepath='/img/clientes/qeb/logo.png' nav='/success-cases/#qeb'></RoundedSlide>
                    <RoundedSlide imagepath='/img/clientes/xzeal/logo.webp' nav='/success-cases/#xzeal'></RoundedSlide>
                </Slider3>
            </section>
            <section ref={dpSectionRef} className={styles.successCase}>
                <div className={styles.successImages}>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/clientes/dagpacket/dagpacketexito3.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/clientes/dagpacket/dagpacketexito1.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/clientes/dagpacket/dagpacketexito2.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/clientes/dagpacket/dagpacketexito4.png)' }}></div>
                </div>
                <div className={styles.successDescription}>
                    <img src="/img/clientes/dagpacket/logo.png"/>
                    <div>
                        <h3>Revolucionando el envío y recepción de paquetes</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>En un entorno cada vez más demandante, la logística de envío y recepción de paquetes debe ser ágil y adaptable a las necesidades de los usuarios. Sin embargo, muchos sistemas actuales no logran cumplir con las expectativas de los consumidores, quienes buscan flexibilidad y rapidez. El Proyecto Dagpacket nació con el objetivo de ofrecer una solución innovadora, permitiendo a los usuarios enviar y recibir paquetes en lugares específicos, sin necesidad de desplazarse a una agencia de envíos.</p>
                        </div>
                    </div>
                    <div>
                        <h3>MEJORAR LA EFICIENCIA Y LA EXPERIENCIA DEL USUARIO</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>El cliente enfrentaba el desafío de mejorar un sistema anterior que no funcionaba correctamente. Además, buscaban modernizar su servicio, integrando nuevas tecnologías que permitieran al usuario realizar el envío y recepción de paquetes desde un único punto, sin importar la compañía de transporte. La necesidad de centralizar estos procesos y garantizar una experiencia de usuario fluida y eficiente era fundamental.</p>
                        </div>
                    </div>
                    <div>
                        <h3>UN SISTEMA AVANZADO Y FLEXIBLE</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>En Deepia, abordamos este reto modernizando la tecnología utilizada previamente. El proceso comenzó con un análisis detallado de los requerimientos del cliente, seguido de una propuesta tecnológica que fue aprobada e implementada exitosamente. El sistema ahora cuenta con comunicación en tiempo real, habilitada mediante la plataforma MQTT, que permite el envío de datos instantáneos al servidor. Además, se integraron funcionalidades clave, como el análisis volumétrico de paquetes utilizando imágenes, lo que mejora significativamente la precisión y velocidad del sistema. Para garantizar la seguridad y escalabilidad, se llevaron a cabo pruebas de estrés para validar el funcionamiento bajo las condiciones más extremas.</p>
                        </div>
                    </div>
                    <div>
                        <h3>MÁS ALLÁ DE LA EFICIENCIA OPERATIVA</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>El impacto más notable de Dagpacket es la expansión del servicio de paquetería, ahora disponible en más ubicaciones sin la necesidad de operadores presentes. Esto no solo aumenta la cantidad de usuarios que acceden al servicio, sino que también mejora la eficiencia operativa al centralizar los envíos de diferentes compañías desde un mismo punto. La implementación de tecnologías emergentes asegura que el sistema siga siendo innovador y adaptable a las futuras necesidades del cliente.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section ref={ecmSectionRef} className={styles.successCase}>
                <div className={styles.successImages}>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/clientes/dagpacket/dagpacketexito3.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/clientes/dagpacket/dagpacketexito1.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/clientes/dagpacket/dagpacketexito1.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/clientes/dagpacket/dagpacketexito2.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/clientes/dagpacket/dagpacketexito4.png)' }}></div>
                </div>
                <div className={styles.successDescription}>
                    <img src="/img/clientes/ecm/logo.png"/>
                    <div>
                        <h3>INNOVACIÓN EN LA CONEXIÓN DE MÉDICOS Y PACIENTES</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>El reclutamiento de pacientes para ensayos clínicos, particularmente en oncología, puede ser un proceso largo y complicado. Los médicos especializados en estos estudios requieren de una plataforma eficiente que les permita encontrar pacientes aptos de manera rápida, mientras que los pacientes necesitan un medio accesible para conectarse con estos ensayos. El Proyecto Clinical Trials fue diseñado para optimizar este proceso, facilitando la conexión entre médicos y pacientes, y reduciendo los tiempos de inscripción y selección. El objetivo principal del proyecto es crear una plataforma que permita a los médicos seleccionar pacientes adecuados para cada ensayo clínico de manera más eficiente, al mismo tiempo que brinda a los pacientes la oportunidad de aplicar fácilmente a los ensayos disponibles.</p>
                        </div>
                    </div>
                    <div>
                        <h3>OPTIMIZACIÓN DEL PROCESO DE RECLUTAMIENTO Y GESTIÓN</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>El cliente enfrentaba el desafío de agilizar el proceso de búsqueda y selección de pacientes para ensayos clínicos, que a menudo era lento y complicado. Necesitaban un sistema que no solo permitiera gestionar las inscripciones de manera automatizada, sino que también facilitara la comunicación entre médicos y pacientes, ofreciendo transparencia en todo momento.</p>
                        </div>
                    </div>
                    <div>
                        <h3>PLATAFORMA AUTOMATIZADA Y ACCESIBLE</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>Para abordar este reto, se desarrolló una web app que permite a los médicos subir detalles de los ensayos clínicos, especificar requisitos y gestionar el proceso de selección de pacientes de manera automatizada. El sistema cuenta con una interfaz de usuario intuitiva y accesible desde cualquier dispositivo, lo que facilita la interacción tanto para médicos como para pacientes. Entre las características técnicas más destacadas se incluyen:<br/>• Registro y comparación de pacientes basado en los requisitos del ensayo clínico.<br/>• Notificaciones automáticas por correo electrónico, que mantienen a los pacientes informados sobre su estado en el proceso de selección.<br/>• Integración con APIs de geolocalización, lo que permite a los pacientes encontrar ensayos cercanos a su ubicación.<br/>• Sistema automatizado de respuestas, facilitando la comunicación entre médicos y pacientes. Seguridad y Escalabilidad El sistema fue diseñado para ser escalable, permitiendo un aumento en el número de ensayos, médicos y pacientes sin comprometer el rendimiento. Se implementaron estrictos protocolos de seguridad, como el cifrado de datos y la autenticación segura, para proteger la información médica y personal de los usuarios.</p>
                        </div>
                    </div>
                    <div>
                        <h3>IMPACTO Y BENEFICIOS PARA EL CLIENTE</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>La implementación de Clinical Trials ha tenido un impacto significativo en el cliente. Los principales beneficios incluyen:<br/>• Mayor eficiencia en el proceso de selección de pacientes, permitiendo a los médicos encontrar candidatos adecuados de manera más rápida y con menos esfuerzo.<br/>• Mejora en la comunicación, gracias a las notificaciones automáticas, lo que reduce la incertidumbre para los pacientes y facilita su participación en los ensayos.<br/>• Accesibilidad desde cualquier dispositivo, lo que hace que tanto médicos como pacientes puedan interactuar con la plataforma en cualquier momento y lugar. Clinical Trials ha transformado el proceso de inscripción en ensayos clínicos, optimizando el flujo de trabajo y proporcionando a los pacientes una vía clara y accesible para participar en investigaciones que pueden mejorar sus opciones de tratamiento.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section ref={nemuSectionRef} className={styles.successCase}>
                <div className={styles.successImages}>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/dagpacketexito3.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/dagpacketexito1.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/dagpacketexito1.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/dagpacketexito2.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/dagpacketexito4.png)' }}></div>
                </div>
                <div className={styles.successDescription}>
                    <img src='/img/clientes/nemu/logo.webp'/>
                    <div>
                        <h3>SIMPLIFICANDO LA BÚSQUEDA Y GESTIÓN DE PROPIEDADES</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>El sector inmobiliario requiere de plataformas modernas y eficientes que permitan tanto a los compradores como a los administradores gestionar propiedades de manera ágil y sin complicaciones. El Proyecto Nemu fue diseñado para ofrecer una solución intuitiva y atractiva que facilita la búsqueda de propiedades en venta o renta, permitiendo a los usuarios filtrar resultados según sus preferencias y a los administradores gestionar propiedades y ofertas de manera eficiente. El principal objetivo de Nemu es ofrecer una plataforma intuitiva y fácil de usar que simplifique la búsqueda de propiedades y permita a los administradores inmobiliarios gestionar su inventario sin complicaciones.</p>
                        </div>
                    </div>
                    <div>
                        <h3>PROMOCIONAR Y GESTIONAR PROPIEDADES DE MANERA EFICIENTE</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>El cliente necesitaba una solución moderna para promocionar sus propiedades y permitir a los usuarios encontrar fácilmente lo que estaban buscando. Además, se requería un sistema que facilitara la gestión interna de propiedades y ofertas, eliminando barreras y simplificando el proceso.</p>
                        </div>
                    </div>
                    <div>
                        <h3>PLATAFORMA INTUITIVA Y VISUALMENTE ATRACTIVA</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>El desarrollo de Nemu incluyó la creación de una interfaz gráfica moderna y minimalista, enfocada en la usabilidad. Los usuarios pueden buscar propiedades mediante filtros personalizables, como precio, ubicación, y amenidades. Además, se integraron herramientas como Maplibre para mostrar las propiedades en un mapa interactivo, lo que mejora la experiencia de búsqueda. Entre las características técnicas más destacadas se incluyen:<br/>• Búsqueda filtrada de propiedades basada en parámetros como precio, ubicación y amenidades.<br/>• Visualización de propiedades en un mapa interactivo, facilitando la búsqueda geográfica mediante pines que representan la ubicación de cada propiedad.<br/>• CMS robusto que permite a los administradores gestionar propiedades, ofertas y toda la información relacionada de manera eficiente. Seguridad y Escalabilidad La plataforma fue diseñada para ser escalable, permitiendo el crecimiento del número de propiedades y usuarios sin afectar el rendimiento. En cuanto a la seguridad, se implementaron medidas de autenticación y cifrado para proteger los datos sensibles de los usuarios y la información sobre las ofertas.</p>
                        </div>
                    </div>
                    <div>
                        <h3>IMPACTO Y BENEFICIOS CLAVE</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>Los resultados de Nemu han sido muy positivos para el cliente, logrando varios beneficios importantes:<br/>• Mejora en la promoción de propiedades, ya que la plataforma facilita la búsqueda y presenta las propiedades visualmente atractivas.<br/>• Gestión eficiente, permitiendo a los administradores inmobiliarios manejar el inventario de propiedades y las ofertas de los clientes de manera ágil y sin complicaciones.<br/>• Aumento en las conversiones, ya que al facilitar la búsqueda mediante filtros personalizables y la presentación visual de las propiedades en un mapa, se incrementa la probabilidad de que los usuarios encuentren propiedades que se ajusten a sus necesidades.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section ref={mageovaSectionRef} className={styles.successCase}>
                <div className={styles.successImages}>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/dagpacketexito3.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/dagpacketexito1.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/dagpacketexito2.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/dagpacketexito4.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/dagpacketexito1.png)' }}></div>
                </div>
                <div className={styles.successDescription}>
                    <img src="/img/clientes/mageova/logo.webp"/>
                    <div>
                        <h3>CONECTIVIDAD PÚBLICA Y PUBLICIDAD INTELIGENTE</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>En un mundo donde el acceso a Internet es una necesidad fundamental, muchos espacios públicos aún enfrentan limitaciones en conectividad. El Proyecto Mageova fue desarrollado para enfrentar este desafío, proporcionando acceso gratuito a WiFi en paradas de transporte público y, al mismo tiempo, creando una plataforma publicitaria innovadora. Esto permite a las marcas aprovechar los momentos en los que los consumidores acceden a la red para exponerlos a campañas publicitarias personalizadas.</p>
                        </div>
                    </div>
                    <div>
                        <h3>CONECTIVIDAD LIMITADA Y OPTIMIZACIÓN PUBLICITARIA</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>El cliente necesitaba ofrecer acceso a Internet en áreas públicas, particularmente en paradas de transporte, donde la conectividad es escasa. Al mismo tiempo, buscaban una forma de medir el impacto de las campañas publicitarias que los usuarios visualizarían mientras se conectaban a la red WiFi, así como recopilar datos útiles para mejorar la segmentación.</p>
                        </div>
                    </div>
                    <div>
                        <h3>CONECTIVIDAD GRATUITA Y PUBLICIDAD PERSONALIZADA</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>El equipo de desarrollo diseñó una solución que integra una red WiFi pública gratuita con una plataforma publicitaria inteligente. La autenticación de los usuarios se gestiona a través de servicios de inicio de sesión, y el acceso está precedido por una breve campaña publicitaria. Esta funcionalidad no solo permite a los usuarios acceder a la red, sino que también recopila datos valiosos sobre su comportamiento y preferencias. Entre las características técnicas más destacadas se encuentran:<br/>• Generación automática de campañas publicitarias en HTML, CSS, PHP y JS.<br/>• Dashboard en tiempo real para que las empresas monitoreen el rendimiento de sus campañas.<br/>• Recopilación de datos detallados, como el sistema operativo de los usuarios, tiempo de conexión y número de visualizaciones de los anuncios. Seguridad y Escalabilidad El sistema fue diseñado para soportar un alto número de usuarios simultáneos, asegurando la escalabilidad conforme la demanda crece. Además, se implementaron estrictos protocolos de seguridad para proteger la información de los usuarios y garantizar un acceso controlado a la red WiFi.</p>
                        </div>
                    </div>
                    <div>
                        <h3>IMPACTO EN LA CONECTIVIDAD Y EN EL ALCANCE DE LAS MARCAS</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>Gracias a Mageova, las marcas han podido incrementar su visibilidad y optimizar sus campañas publicitarias mediante un análisis más detallado del comportamiento de los usuarios conectados. Los resultados más significativos incluyen:<br/>• Mejor segmentación de las audiencias, permitiendo a las empresas optimizar sus campañas publicitarias de manera precisa.<br/>• Aumento en la exposición publicitaria, ya que los usuarios vuelven a conectarse repetidamente a la red WiFi.<br/>• Datos en tiempo real, lo que facilita la toma de decisiones estratégicas para mejorar la efectividad de las campañas. Mageova ha mejorado la experiencia de los usuarios de transporte público, proporcionando un servicio de valor añadido, mientras que las marcas han visto un incremento significativo en el alcance de sus campañas.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section ref={qebSectionRef} className={styles.successCase}>
                <div className={styles.successImages}>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/dagpacketexito3.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/dagpacketexito1.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/dagpacketexito2.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/dagpacketexito4.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/dagpacketexito1.png)' }}></div>
                </div>
                <div className={styles.successDescription}>
                    <img src="/img/clientes/qeb/logo.png"/>
                    <div>
                        <h3>UNIFICANDO LA PUBLICIDAD FÍSICA CON TECNOLOGÍA</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>Aunque vivimos en una era digital, la publicidad física sigue siendo fundamental para las marcas que quieren llegar a su público de manera efectiva. Sin embargo, gestionar inventarios y campañas en medios físicos puede ser un desafío, especialmente cuando todo está disperso en diferentes plataformas. Ahí es donde entra el Proyecto QEB: una solución innovadora pensada para simplificar y optimizar la gestión de la publicidad física.</p>
                        </div>
                    </div>
                    <div>
                        <h3>UNIFICAR Y AUTOMATIZAR</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>Las empresas se enfrentaban a un problema común: operaciones fragmentadas en diferentes plataformas para manejar inventarios, clientes, empleados y campañas. Esto no solo generaba ineficiencias, sino también una carga operativa innecesaria. QEB surgió con un propósito claro: simplificar procesos y ahorrar tiempo, permitiendo a las empresas concentrarse en lo que realmente importa—ofrecer un servicio publicitario de calidad.</p>
                        </div>
                    </div>
                    <div>
                        <h3>TECNOLOGÍA SENCILLA Y POTENTE</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>En Deepia, nos propusimos resolver este problema con una solución que combina tecnología avanzada con la simplicidad que las empresas necesitan para ser más productivas. El Proyecto QEB se ha desarrollado en fases, creando una plataforma integral que centraliza la gestión de inventarios y campañas desde un único punto. Además, el sistema incorpora chatbots e inteligencia artificial (IA), que generan resúmenes automáticos a partir de la información recopilada, lo que facilita la toma de decisiones y mejora la experiencia del usuario. Esta integración no solo simplifica la gestión, sino que también automatiza procesos que antes requerían intervención manual, aumentando la eficiencia operativa. Seguridad y Escalabilidad La seguridad es una prioridad para cualquier sistema de gestión empresarial, y en Deepia lo tomamos en serio. Encriptamos los datos tanto en la base de datos como en el front-end, asegurando que toda la información sensible esté protegida. Además, QEB está diseñado para ser escalable, adaptándose a las necesidades crecientes de las empresas sin sacrificar rendimiento o seguridad.</p>
                        </div>
                    </div>
                    <div>
                        <h3>EFICIENCIA Y PRODUCTIVIDAD</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>Tareas que antes consumían mucho tiempo y esfuerzo ahora se ejecutan de manera automática, permitiendo que los equipos se enfoquen en actividades estratégicas y de mayor valor. La integración de plataformas ha eliminado la necesidad de compartir información manualmente entre departamentos, mejorando los tiempos de respuesta y potenciando la productividad. En resumen, QEB ha optimizado el flujo de trabajo, reducido errores y mejorado la comunicación interna, lo que se traduce en una gestión de recursos más eficiente.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section ref={xzealSectionRef} className={styles.successCase}>
                <div className={styles.successImages}>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/dagpacketexito3.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/dagpacketexito1.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/dagpacketexito2.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/dagpacketexito4.png)' }}></div>
                    <div className={styles.successImage} style={{ backgroundImage: 'url(/img/dagpacketexito1.png)' }}></div>
                </div>
                <div className={styles.successDescription}>
                    <img src="/img/clientes/xzeal/logo.webp"/>
                    <div>
                        <h3>INNOVACIÓN EN ECOMMERCE PARA GAMERS Y JÓVENES</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>El mercado del gaming es uno de los sectores más dinámicos y competitivos en la actualidad. Las tiendas en línea tradicionales no siempre logran captar la atención de una audiencia joven y exigente que busca más que una simple compra: desea una experiencia inmersiva y atractiva. El Proyecto xZeal se diseñó para ofrecer una plataforma de eCommerce centrada en las necesidades de los gamers y jóvenes apasionados por los productos electrónicos, donde el diseño visual juega un papel fundamental en la atracción de clientes. El objetivo principal de xZeal es crear una tienda en línea que no solo funcione como un catálogo y punto de venta, sino que también destaque visualmente en un mercado saturado, capturando la atención de las nuevas generaciones a través de una experiencia de compra fluida y atractiva.</p>
                        </div>
                    </div>
                    <div>
                        <h3>CAPTURAR LA ATENCIÓN DEL PÚBLICO GAMER</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>El cliente necesitaba una plataforma que pudiera sobresalir entre la competencia, ofreciendo algo más que una simple lista de productos. La tienda debía ser un espacio visualmente llamativo, con una interfaz moderna que facilitara la navegación y la compra para una audiencia acostumbrada a interacciones rápidas y dinámicas en el mundo digital.</p>
                        </div>
                    </div>
                    <div>
                        <h3>ECOMMERCE DINÁMICO CON DISEÑO GAMER</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>Para abordar este desafío, se desarrolló una plataforma de eCommerce con un diseño gráfico vibrante, utilizando colores neón y gráficos personalizados para atraer al público objetivo. El sitio incorpora contenido multimedia, como fotos y videos, que permite a los usuarios interactuar de manera dinámica con el catálogo de productos. Entre las características técnicas más destacadas se incluyen:<br/>• Catálogo interactivo con fotos y videos que muestran los productos desde diferentes ángulos y con distintas configuraciones.<br/>• Pasarela de pago integrada con Stripe, que ofrece una experiencia de compra segura y confiable.<br/>• Diseño visual atractivo que captura la atención de los usuarios, utilizando colores y gráficos que resuenan con la cultura gamer. Seguridad y Escalabilidad La plataforma fue diseñada para ser escalable, permitiendo la fácil adición de nuevos productos y características sin comprometer el rendimiento. Además, la integración de Stripe garantiza que todas las transacciones sean seguras, protegiendo la información financiera de los usuarios mediante protocolos de cifrado y medidas adicionales contra fraudes.</p>
                        </div>
                    </div>
                    <div>
                        <h3>IMPACTO EN LA ATRACCIÓN Y CONVERSIÓN DE USUARIOS</h3>
                        <div className={styles.flexRow}>
                            <VSeparator black/>
                            <p>El impacto de xZeal en el cliente ha sido notable, logrando varios beneficios clave:<br/>• Aumento en la atracción del público joven gracias a su diseño visualmente atractivo y su enfoque en mejorar la experiencia de compra de los gamers.<br/>• Mejora en las ventas, ya que la integración de un sistema de pago seguro ha incrementado la confianza de los usuarios, resultando en un mayor número de transacciones exitosas.<br/>• Mayor interacción con el catálogo, dado que los usuarios pueden visualizar fotos y videos de los productos, lo que ha aumentado las tasas de conversión al ofrecer una experiencia de compra más inmersiva y detallad</p>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export { Success };