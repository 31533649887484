import { React, useState } from "react";
import styles from "./CarouselLinkedLead.module.css";
import { ButtonA } from "../ButtonA/ButtonA";
import rightIcon from '../../assets/img/workvision/rightArrow.webp';
import leftIcon from '../../assets/img/workvision/leftArrow.webp';

const CarouselLinkedLead = () => {
    const images = [
        '/img/cflinked3.png',
        '/img/cflinked4.png',
        '/img/cflinked3.png',
        '/img/cflinked4.png',
        '/img/cflinked5.png',
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
         setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const visibleImages = () => {
    const totalImages = images.length;
    const visible = [];
    for (let i = 0; i < 3; i++) {
        visible.push(images[(currentIndex + i) % totalImages]);
    }
    return visible;
    };

    return (
        <div className={styles.linkedCarouselContainer}>
            <div className={styles.linkedCarousel}>
                {visibleImages().map((img, index) => (
                <img 
                    key={index} 
                    src={img} 
                    alt={`Imagen ${currentIndex + index + 1}`} 
                />
                ))}
            </div>
            <div className={styles.buttonsContainer}>
                <ButtonA icon={leftIcon} onClick={handleNext} pad='12px 42px'/>
                <ButtonA icon={rightIcon} onClick={handlePrev} pad='12px 42px'/>
            </div>
        </div>
    );
}

export { CarouselLinkedLead };