import { React, useState } from 'react';
import styles from './Slider3.module.css';

const Slider3 = ({ children }) => {
    const [sliderIndex, setSliderIndex] = useState(0);
    const handleSliderChange = (e) => {
        setSliderIndex(e.target.value);
        console.log(e.target.value);
        console.log(children.length);
        
      };

    const getMax = children.length > 3 ? children.length - 3 : children.length;

    return (
        <div className={styles.sliderContainer}>
            <div className={styles.slider} style={{ transform: `translateX(-${sliderIndex * 33.8}%)` }}>
                {children}
            </div>
            <div className={styles.sliderControl}>
                <input type="range" id="sliderRange" min="0" max={getMax} defaultValue="0" onChange={handleSliderChange}/>
            </div>
        </div>
    )
}

export default Slider3;