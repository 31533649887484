import React from "react";
import styles from './Slide2x2.module.css';

function Slide2x2({children}) {
    return (
        <div className={styles.div2x2}>
            {children}
        </div>
    );
}

export { Slide2x2 };