import React from "react";
import styles from "./FAQs.module.css";
import { ButtonA } from "../ButtonA/ButtonA";


const FAQs = ({ number, text, children }) => {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <div className={styles.faq}>
            <div className={styles.faqHeader}>
                <h3>{number}</h3>
                <div className={styles.openButton}><div onClick={() => setIsOpen(!isOpen)} ><img src="/img/leftArrow.webp" style={{ transform: isOpen ? 'scaleX(100%)' : 'scaleX(-100%)' }}/></div></div>
            </div>
            <div className={styles.faqContent}>
                <h4>{text}</h4>
                <div style={{ maxHeight: isOpen ? '1000px' : '0'}}>{children}</div>
            </div>
        </div>
    );
}

export { FAQs };