import React from "react";
import styles from "./TextBigNumber.module.css";

const TextBigNumber = ({ number, text, black }) => {
    return (
        <div className={styles.textBigNumberContainer}>
            <span style={{color: black ? '#000' : '#fff' }}>{number}</span>
            <p style={{color: black ? '#000' : '#fff', backgroundColor: black ? '#FFF' : '#000' }}>{text}</p>
        </div>
    );
};

export { TextBigNumber };