import React from "react";
import styles from "./Hardware.module.css";
import { Footer } from "../../components/Footer/Footer";
import Spline from '@splinetool/react-spline';
import { VSeparator } from "../../components/VSeparator/VSeparator";
import { TextBigNumber } from "../../components/TextBigNumber/TextBigNumber";
import NavBar from "../../components/NavBar/NavBar";

const Hardware = () => {
    return (
        <main id={styles.hardware}>
            <NavBar black/>
            <section>
                <div className={styles.hero3D}>
                    <Spline scene="https://prod.spline.design/m2FxVkfaggBtcznx/scene.splinecode" style={{ height: '100%'}}/>
                </div>
                <div className={styles.heroText}>
                    <h2>Diseñamos hardware + AI</h2>
                    <p>Censamos y conectamos al mundo</p>
                </div>
            </section>
            <section className={styles.section2}>
                <div className={styles.flexRow}>
                    <VSeparator black/>
                    <p>Empoderamos la industria Mexicana democratizando el acceso a la tecnología dotando control completo de su tecnología a las empresas.</p>
                </div>
                <div className={styles.flexCol}>
                    <h2>Nuestra especialidad</h2>
                    <div className={styles.flexRow}>
                        <VSeparator black/>
                        <p>Nos especializamos en diseñar hardware a la medida, centrándonos en la tecnología de sensores avanzados para consumidores y soluciones industriales de Internet de las cosas (IoT) de alta calidad con modelos de inteligencia artificial.</p>
                    </div>
                </div>
            </section>
            <section className={styles.section3}>
                <div>
                    <TextBigNumber number="01" text="TECNOLOGÍA DE CONSUMO" black/>
                    <span className={styles.serviceSpan}>Dispositivos de sensado para uso personal.</span>
                </div>
                <div>
                    <TextBigNumber number="02" text="LOT INDUSTRIAL Y SMART CITIES" black/>
                    <span className={styles.serviceSpan}>Hacemos posible productos industriales para pensar todo tipo de equipos.</span>
                </div>
                <div>
                    <TextBigNumber number="03" text="AI ON DEVICES" black/>
                    <span className={styles.serviceSpan}>Sensamos, creamos y analizamos data ser para mostrarlos en dispositivos.</span>
                </div>
            </section>
            <section className={styles.section4}>
                <h2>DESARROLLOS</h2>
                <h3>SYPTEC</h3>
                <h3>MG1</h3>
            </section>
            <Footer black/>
        </main>
        
    );
}

export default Hardware;