import React, { useState, useRef } from "react";
import styles from './WebApps.module.css';
import { Footer } from '../../components/Footer/Footer';
import Card1bg from '../../assets/img/web/card1.png';
import Card2bg from '../../assets/img/web/card2.png';
import Card3bg from '../../assets/img/web/card3.png';
import Card4bg from '../../assets/img/web/card4.png';
import Card5bg from '../../assets/img/web/card5.png';
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import Spline from "@splinetool/react-spline";
import NavBar from "../../components/NavBar/NavBar";

function WebApps() {
  const alignCenter = { display: 'flex', alignItems: 'center' }
  
  return (
    <div>
      <NavBar />
      <div className={styles.background} />
      <Parallax pages={12} style={{ flex: 1 }}>
        
        {/* Header fijo */}
        <ParallaxLayer sticky={{ start: 0, end: 11 }} style={{ zIndex: 10 }}>
        </ParallaxLayer>

        <ParallaxLayer className={`${styles.containerWeb} ${styles.parallax}`} offset={0} speed={0.5} style={{ ...alignCenter, justifyContent: 'center' }}>
          <div className={styles.hero}>
            <Spline scene="https://prod.spline.design/l8kouQQFYZVBCzAX/scene.splinecode" style={{ height: '100%'}}/>
            <p className={styles.scrollText}>Nos dedicamos al desarrollo de software y webs a medida que
              transforman la visión de nuestros clientes en soluciones
              digitales innovadoras. Nos especializamos en la creación de
              experiencias inmersivas en tercera dimensión con animaciones
              complejas, aprovechando tecnologías de vanguardia como la IA.
            </p>
          </div>
        </ParallaxLayer>
        
        {/* Sección destacada */}
        <ParallaxLayer className={styles.parallax} sticky={{ start: 1, end: 8 }} style={{...alignCenter, justifyContent:'center'}}>
          <div className={styles.textD} >
             <h1 className={styles.bigTitle}>DESTACA</h1>
             <p className={styles.smallTitle}>de la competencia con un sitio web diseñado a tu medida</p>
          </div>
        </ParallaxLayer>
        
        {/* Secciones con tarjetas */}
        <ParallaxLayer className={styles.parallax} sticky={{ start: 2, end: 7 }} speed={1.5} style={{ ...alignCenter, justifyContent: 'center' }}>
          <div className={`${styles.Card} ${styles.parallax}`}>
           <img className={styles.Card5} src={Card4bg} alt="Card"></img>
          </div>
        </ParallaxLayer>
        <ParallaxLayer className={styles.parallax} sticky={{ start: 2, end: 6 }} speed={1.5} style={{ ...alignCenter, justifyContent: 'center' }}>
          <div className={`${styles.Card} ${styles.parallax} `}>
           <img className={styles.Card4} src={Card3bg} alt="Card"></img>
          </div>
        </ParallaxLayer>
        <ParallaxLayer className={styles.parallax} sticky={{ start: 2, end: 5 }} speed={1.5} style={{ ...alignCenter, justifyContent: 'center' }}>
          <div className={`${styles.Card} ${styles.parallax} `}>
           <img className={styles.Card3} src={Card5bg} alt="Card" ></img>
          </div>
        </ParallaxLayer>
        <ParallaxLayer className={styles.parallax} sticky={{ start: 2, end: 4 }} speed={1.5} style={{ ...alignCenter, justifyContent: 'center' }}>
          <div className={`${styles.Card} ,${styles.parallax} `}>
           <img className={styles.Card2} src={Card2bg} alt="Card"></img>
          </div>
        </ParallaxLayer>
        <ParallaxLayer className={styles.parallax} sticky={{ start: 2, end: 3 }} speed={1.5} style={{ ...alignCenter, justifyContent: 'center' }}>
          <div className={`${styles.Card} ${styles.parallax} `}>
           <img className={`${styles.Card1} ${styles.rotated}`} src={Card1bg}  alt="Card"></img>
          </div>
        </ParallaxLayer>

        {/* Sección de "Desarrollo a la Medida" */}
        <ParallaxLayer className={styles.parallax} sticky={{ start: 9, end: 10 }}  speed={1.5} style={{ ...alignCenter, justifyContent: 'center' }}>
           <div className={styles['container-text']}>
            <h1 className={styles.big3Title}>DESARROLLO <br/>A LA MEDIDA</h1>
            <div className={styles['text-2']}>
              <p className={styles.smallTitle}>desarrollo</p>
              <p className={styles.smallTitle}>a la medida</p>
            </div>
           </div>    
        </ParallaxLayer>

        {/* Footer */}
        <ParallaxLayer sticky={{ start:11, end: 11 }} speed={1.5} style={{ ...alignCenter, justifyContent: 'center' }}>
          <Footer />
        </ParallaxLayer>
        
      </Parallax>
    </div>
  )
}

export default WebApps;