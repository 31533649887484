import { React, useEffect, useRef, useState } from 'react';
import styles from './Home.module.css';
import { ContinueArrow } from '../../components/ContinueArrow/ContinueArrow';
import { Service } from '../../components/Service/Service';
import Slider3 from '../../components/Slider3/Slider3';
import { RoundedSlide } from '../../components/Slider3Components/RoundedSlide/RoundedSlide';
import { Slide2x2 } from '../../components/Slider3Components/Slide2x2/Slide2x2';
import { TextSlide } from '../../components/Slider3Components/TextSlide/TextSlide';
import { HSeparator } from '../../components/HSeparator/HSeparator';
import { VSeparator } from '../../components/VSeparator/VSeparator';
import NavBar from '../../components/NavBar/NavBar';
import emailjs from '@emailjs/browser';

function Home() {

  const service1Img = 'https://deepia.computer/img/service1.webp';
  const service2Img = 'https://deepia.computer/img/service2.webp';
  const service3Img = 'https://deepia.computer/img/service3.webp';
  const service4Img = 'https://deepia.computer/img/service4.webp';
  const [activeForm, setActiveForm] = useState(false);
  const [completedForm, setCompletedForm] = useState(false);

  const scrollContainerRef = useRef(null);
  const form = useRef(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
    // .sendForm('service_7z5zv5d', 'template_7z5zv5d', form.current, {
    .sendForm('service_q7b386u', 'template_hqm0154', form.current, {
      publicKey: '-N1_TJeOHonbo-nAd',
    })
    .then(
      (result) => {
        console.log(result.text);
        console.log('success');
        setCompletedForm(true);
      },
      (error) => {
        console.log(error.text);
      }
    );
  }

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleWheel = (event) => {
      // Previene el comportamiento normal de scroll en Y
      event.preventDefault();
      
      // Desplaza el contenedor horizontalmente usando el delta del evento
      scrollContainer.scrollLeft += event.deltaY * 2;
    };

    // Añade el event listener
    scrollContainer.addEventListener('wheel', handleWheel);

    // Cleanup cuando el componente se desmonta
    return () => {
      scrollContainer.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <main className={styles.main} ref={scrollContainerRef}>
      <NavBar />
      <div className={styles.content}>
        <section className={styles.section1} id='section1'>
          <div className={styles.sloganContainer}>
            <h3>Tecnología innovadora para transformar tu negocio con IA</h3>
          </div>
          <ContinueArrow/>
        </section>
        <section className={styles.section2}>
          <div className={styles.asteroid}>
            <div className={styles.infoSquare}>
              <HSeparator/>
              <p>Diseñamos innovaciones de vanguardia para empresas y organizaciones, abordando desafíos complejos con soluciones en web, aplicaciones, software y hardware basadas en Inteligencia Artificial, ofreciendo un futuro tecnológico completo.</p>
            </div>
          </div>
          <div className={styles.container}>
            <VSeparator/>
            <h2>DEEPIA<br/> Y LA EVOLUCIÓN TECNOLÓGICA</h2>
          </div>
        </section>
        <section className={styles.section3}>
          <Service serviceID="web" title='WEB/APPS' ></Service>
          <Service serviceID="linkedlead" title='LINKEDLEAD' ></Service>
          <Service serviceID="workvision" title='WORKVISION' ></Service>
          <Service serviceID="hardware" title='HARDWARE' ></Service>
        </section>
        <section className={styles.section4}>
          <div className={styles.container}>
          <VSeparator/>
            <h2>SOLUCIONES PERSONALIZADAS E INNOVADORAS</h2>
          </div>
          <div className={styles.infoSquare}>
          <HSeparator/>
            <p>Fusionar la Inteligencia artificial con el diseño integral de soluciones, lo que nos permite ofrecer productos altamente personalizados y adaptables a necesidades específicas.<br/><br/>Esta sinergia entre IA, diseño integral y personalización nos distingue al ofrecer a nuestros clientes soluciones software y hardware innovadoras, flexibles y de alto rendimiento, que permiten evolucionar y escalar tu modelo de negocio.</p>
          </div>
        </section>
        <section className={styles.section5}>
        </section>
        <section className={styles.section6}>
          <h2>TRANSFORMAMOS RETOS EN ÉXITOS</h2>
          <Slider3>
            <RoundedSlide imagepath='/img/clientes/dagpacket/logo.png' nav='/success-cases/#dagpacket'></RoundedSlide>
            <RoundedSlide imagepath='/img/clientes/ecm/logo.png' nav='/success-cases/#ecm'></RoundedSlide>
            <RoundedSlide imagepath='/img/clientes/nemu/logo.webp' nav='/success-cases/#nemu'></RoundedSlide>
            <RoundedSlide imagepath='/img/clientes/mageova/logo.webp' nav='/success-cases/#mageova'></RoundedSlide>
            <RoundedSlide imagepath='/img/clientes/qeb/logo.png' nav='/success-cases/#qeb'></RoundedSlide>
            <RoundedSlide imagepath='/img/clientes/xzeal/logo.webp' nav='/success-cases/#xzeal'></RoundedSlide>
          </Slider3>
        </section>
        <section className={styles.section7}>
            <div className={styles.section7Content}>
                <div className={styles.button} onClick={() => setActiveForm(true)}>CONTACTO</div>
                <div className={styles.emailContainer}>
                    <img src='/img/icono.svg' alt='Deepia logo'/>
                    <a href='mailto:contacto@deepia.computer'>CONTACTO@DEEPIA.COMPUTER</a>
                </div>
            </div>
            <div className={`${styles.contactFormContainer} ${activeForm ? styles.active : ''}`}>
              {!completedForm && 
              <div className={styles.formContainer}>
                <img className={styles.logo} src='/img/logo2.svg'></img>
                <img className={styles.closeIcon} src='/icon/icon.svg' alt='Deepia Logo' onClick={() => setActiveForm(false)}/>
                <p>Por favor, rellene el siguiente formulario y uno de nuestros comerciales se pondrá en contacto con usted lo antes posible</p>
                <form ref={form} onSubmit={sendEmail}>
                    <input type='text' name='fromName' placeholder='Nombre Completo' className={styles.doubleColumn} required/>
                    <input type='text' name='phone' placeholder='TELÉFONO' required/>
                    <input type='email' name='email' placeholder='Correo' required/>
                    <input type='text' name='service' placeholder='Servicio' className={styles.doubleColumn} required/>
                    <input type='text' name='position' placeholder='Puesto' required/>
                    <input type='text' name='company' placeholder='Compañia' required/>
                    <input type='text' name='industry' placeholder='Industria de la empresa' required/>
                    <input type='text' name='city' placeholder='Ciudad de su compañia' required/>
                    <textarea name='message' placeholder='Detalles adicionales' className={styles.doubleColumn} required></textarea>
                    <br/>
                    <button type='submit'>ENVIAR</button>
                </form>
              </div>}
              {completedForm && 
              <div className='formContainer'>
                <img className='logo' src='/img/logo2.svg'></img>
                <img className='closeIcon' src='/icon/icon.svg' alt='Deepia Logo' onClick={() => setActiveForm(false)}/>
                <h2>¡Gracias por tu mensaje!</h2>
              </div>}
            </div>
        </section>
        <section className={styles.section8}>
            <h2>CASOS DE ÉXITO</h2>
            <Slider3>
              <RoundedSlide imagepath='/img/clientes/dagpacket/dagpacketexito5.png'/>
              <Slide2x2>
                <RoundedSlide imagepath='/img/clientes/dagpacket/dagpacketexito4.png'/>
                <RoundedSlide imagepath='/img/clientes/dagpacket/dagpacketexito3.png'/>
                <RoundedSlide imagepath='/img/clientes/dagpacket/dagpacketexito2.png'/>
                <RoundedSlide imagepath='/img/clientes/dagpacket/dagpacketexito1.png'/>
              </Slide2x2>
              <TextSlide title='Centralización y Expansión con soluciones tecnolócigas a medida'>
                <p>Con el desarrollo de un sistema avanzado, ayudamos a optimizar el envío y recepción de paquetes, integrando tecnologías que mejoran la eficiencia operativa y la experiencia del usuario.</p>
              </TextSlide>
              <RoundedSlide imagepath='/img/clientes/dagpacket/dagpacketexito5.png'/>
              <Slide2x2>
                <RoundedSlide imagepath='/img/clientes/dagpacket/dagpacketexito4.png'/>
                <RoundedSlide imagepath='/img/clientes/dagpacket/dagpacketexito3.png'/>
                <RoundedSlide imagepath='/img/clientes/dagpacket/dagpacketexito2.png'/>
                <RoundedSlide imagepath='/img/clientes/dagpacket/dagpacketexito1.png'/>
              </Slide2x2>
              <TextSlide title='Centralización y Expansión con soluciones tecnolócigas a medida'>
                <p>Con el desarrollo de un sistema avanzado, ayudamos a optimizar el envío y recepción de paquetes, integrando tecnologías que mejoran la eficiencia operativa y la experiencia del usuario.</p>
              </TextSlide>
              <RoundedSlide imagepath='/img/clientes/dagpacket/dagpacketexito5.png'/>
              <Slide2x2>
                <RoundedSlide imagepath='/img/clientes/dagpacket/dagpacketexito4.png'/>
                <RoundedSlide imagepath='/img/clientes/dagpacket/dagpacketexito3.png'/>
                <RoundedSlide imagepath='/img/clientes/dagpacket/dagpacketexito2.png'/>
                <RoundedSlide imagepath='/img/clientes/dagpacket/dagpacketexito1.png'/>
              </Slide2x2>
              <TextSlide title='Centralización y Expansión con soluciones tecnolócigas a medida'>
                <p>Con el desarrollo de un sistema avanzado, ayudamos a optimizar el envío y recepción de paquetes, integrando tecnologías que mejoran la eficiencia operativa y la experiencia del usuario.</p>
              </TextSlide>
            </Slider3>
        </section>
      </div>
    </main>
  );
}

export default Home;