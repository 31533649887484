import React from "react";
import styles from "./TextSlide.module.css";
import { HSeparator } from "../../HSeparator/HSeparator";

const TextSlide = (props) => {
    return (
        <div className={styles.textSlideContainer}>
            <div className={styles.textSlide}>
                <h2>{props.title}</h2>
                <HSeparator color="black"/>
                {props.children}
            </div>
        </div>
        
    );
}

export { TextSlide };