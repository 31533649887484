import React from "react";
import styles from "./LinkedLead.module.css";
import Spline from "@splinetool/react-spline";
import { VSeparator } from "../../components/VSeparator/VSeparator";
import { CarouselLinkedLead } from "../../components/CarouselLinkedLead/CarouselLinkedLead";
import { Footer } from "../../components/Footer/Footer";
import { FAQs } from "../../components/FAQs/FAQs";
import NavBar from "../../components/NavBar/NavBar";


function LinkedLead() {
  return (
    <main id={styles.linkedlead}>
      <NavBar />
      <section>
        <div className={styles.hero3D}>
            <Spline scene="https://prod.spline.design/WdjOBzCrGEqIJe5y/scene.splinecode" style={{ height: '100%'}}/>
        </div>
        <div className={styles.heroText}>
            <p>Linked Lead es una herramienta de automatización que ofrece<br/>
            una serie de ventajas y beneficios para los profesionales del<br/>
            área comerciales, optimizando la capacitación de leads.</p>
        </div>
      </section>
      <section className={styles.section2}>
        <div className={styles.flexRow}>
            <VSeparator />
            <p>Optimizar y captar leads cualficados en LinkedIn.<br/>Nuestro diferenciador es ayudarte a implementar estrategias de Marketing Digital, automatizaciones y herramientas </p>
        </div>
        <div>
            <img src='/img/linkedleadMockup.png'/>
        </div>
      </section>
      <section className={styles.section3}>
        <div className={styles.flexRow}>
            <h2>¿Cómo funciona<br/><span>Linked Lead?</span></h2>
        </div>
        <div>
          <CarouselLinkedLead />
        </div>
      </section>
      <section className={styles.section4}>
        <div className={styles.flexCol}>
          <h2>Linked Lead<br/><span>PRO</span></h2>
          <img src='/img/cflinked1.png'></img>
          <span>$8,689 MXN</span>
        </div>
        <div className={styles.flexCol}>
          <h2>Linked Lead<br/><span>PREMIUM</span></h2>
          <img src='/img/cflinked2.png'></img>
          <span>$13,089 MXN</span>
        </div>
      </section>
      <section className={styles.section5}>
        <div className={styles.flexCol}>
          <h2>FAQ'S</h2>
        </div>
        <div className={styles.flexCol}>
          <FAQs number='01' text='¿CÓMO SE IMPLEMENTA LINKED LEAD?'>
            <p>Linked Lead es una herramienta de automatización que ofrece una serie de ventajas y beneficios para los profesionales del área comerciales, optimizando la capacitación de leads.Linked Lead es una herramienta de automatización que ofrece una serie de ventajas y beneficios para los profesionales del área comerciales, optimizando la capacitación de leads.Linked Lead es una herramienta de automatización que ofrece una serie de ventajas y beneficios para los profesionales del área comerciales, optimizando la capacitación de leads.</p>
          </FAQs>
          <FAQs number='02' text='¿CÓMO PUEDO VER LA TASA DE CONVERSIÓN DE LEADS DE LA CAMPAÑA?'>
            <p>Linked Lead es una herramienta de automatización que ofrece una serie de ventajas y beneficios para los profesionales del área comerciales, optimizando la capacitación de leads.Linked Lead es una herramienta de automatización que ofrece una serie de ventajas y beneficios para los profesionales del área comerciales, optimizando la capacitación de leads.Linked Lead es una herramienta de automatización que ofrece una serie de ventajas y beneficios para los profesionales del área comerciales, optimizando la capacitación de leads.</p>
          </FAQs>
          <FAQs number='03' text='¿CÓMO ME ASEGURO DE QUE LOS MENSAJES QUE MANDE NO SE VEAN AUTOMATIZADOS?'>
            <p>Linked Lead es una herramienta de automatización que ofrece una serie de ventajas y beneficios para los profesionales del área comerciales, optimizando la capacitación de leads.Linked Lead es una herramienta de automatización que ofrece una serie de ventajas y beneficios para los profesionales del área comerciales, optimizando la capacitación de leads.Linked Lead es una herramienta de automatización que ofrece una serie de ventajas y beneficios para los profesionales del área comerciales, optimizando la capacitación de leads.</p>
          </FAQs>
          <FAQs number='04' text='¿PUEDO TRABAJAR Y NAVEGAR EN LINKEDIN MIENTRAS LINKED LEAD ESTÁ FUNCIONANDO?'>
            <p>Linked Lead es una herramienta de automatización que ofrece una serie de ventajas y beneficios para los profesionales del área comerciales, optimizando la capacitación de leads.Linked Lead es una herramienta de automatización que ofrece una serie de ventajas y beneficios para los profesionales del área comerciales, optimizando la capacitación de leads.Linked Lead es una herramienta de automatización que ofrece una serie de ventajas y beneficios para los profesionales del área comerciales, optimizando la capacitación de leads.</p>
          </FAQs>
        </div>
      </section>
      <Footer/>
    </main>
  );
}

export default LinkedLead;