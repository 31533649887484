import React from "react";
import { useNavigate } from 'react-router-dom';
import styles from './Service.module.css';

function Service(props) {

    let navigation = useNavigate();

    return (
        <div className={styles.service} id={props.serviceID} style={{ backgroundImage:  `url(` + props.background + `)`}}
            onMouseEnter={() => {
                var service = document.getElementById(props.serviceID);
                service.style.backgroundImage = `url(` + props.background + `)`;
                var originalSrc = service.style.backgroundImage;
                service.style.backgroundImage = originalSrc.replace('.webp', '_anim.webp');
            }}
            onMouseLeave={() => {
                var service = document.getElementById(props.serviceID);
                service.style.backgroundImage =  `url(${props.background})`;
                var originalSrc = service.style.backgroundImage;
                service.style.backgroundImage = originalSrc.replace('_anim.webp', '.webp');
            }}
            onClick={() => navigation(props.serviceID)}
        >
            <div className={styles.serviceBanner} onClick={() => navigation(props.serviceID)}>
                <h1 className={styles.serviceTitle}>{props.title}</h1>
            </div>
        </div>
    );
}

export { Service };
