import React from "react";
import styles from "./VSeparator.module.css";

const VSeparator = ({ black }) => {
    return (
        <div className={styles.vSeparator} style={{ borderColor : black ? '#000' : '#fff' }}>
            <div style={{ background : black ? '#000' : '#fff' }}></div>
        </div>
    );
}

export { VSeparator };