import React from "react";
import styles from './ContinueArrow.module.css'; // Import the module CSS file
import arrow from '../../assets/img/continue-arrow.png';

function ContinueArrow() {
    return (
        <div className={styles.continueArrow}> {/* Use the styles object */}
            <img src={arrow} alt="continue"/>
        </div>
    );
}

export { ContinueArrow };