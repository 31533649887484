import React from "react";
import styles from './NavBar.module.css';
import { NavLink, useNavigate } from "react-router-dom";

function NavBar({ black }) {
    const navigate = useNavigate();
    const [isOpened, setIsOpened] = React.useState(false);
    const [subMenu, setSubMenu] = React.useState(false);

    return (
        <header className={`${styles.header} ${black ? styles.inverted : ''}`}>
            <div className={styles.navigation}>
                <a href='/'><img className='logo' src="/img/logo.png" alt="Logo"></img></a>
                <nav className={styles.navLinks} style={{ transform: isOpened ? 'translateX(0)' : 'translateX(-450px)', backgroundColor: black ? 'white' : ''}}>
                    <ul>
                        <li className={`${black ? styles.blackText : ''}`}><NavLink to='/'><img className={styles.icon} src='/img/home.png' alt="Home"/>HOME</NavLink></li>
                        <li className={`${styles.toggleSolutions} ${black ? styles.blackText : ''}`}>
                            <div>
                                <img className={styles.icon} src='/img/soluciones.png' alt="Soluciones"/>
                                <span>SOLUCIONES</span>
                                <img className={styles.toggleIcon} src='/img/toggleList.png' alt="Toggle" style={{ transform: subMenu ? 'rotate(180deg)' : ''}} onClick={() => setSubMenu(item => !item)}/>
                            </div>
                            <ul style={{ maxHeight: subMenu ? '300px' : '0' }}>
                                <li className={`${styles.solution} ${black ? styles.blackText : ''}`}><NavLink to='/web'>WEB/APP</NavLink></li>
                                <li className={`${styles.solution} ${black ? styles.blackText : ''}`}><NavLink to='/linkedlead'>LINKEDLEAD</NavLink></li>
                                <li className={`${styles.solution} ${black ? styles.blackText : ''}`}><NavLink to='/hardware'>HARDWARE</NavLink></li>
                                <li className={`${styles.solution} ${black ? styles.blackText : ''}`}><NavLink to='/workvision'>WORKVISION</NavLink></li>
                            </ul>
                        </li>
                        <li className={`${black ? styles.blackText : ''}`}><NavLink to='/faqs'><img className={styles.icon} src='/img/faqs.png' alt="FAQs"/>PREGUNTAS FRECUENTES</NavLink></li>
                        <li className={`${black ? styles.blackText : ''}`}><NavLink to='/blog'><img className={styles.icon} src='/img/blog.png' alt="Blog"/>BLOG</NavLink></li>
                        <li className={`${black ? styles.blackText : ''}`}><NavLink to='/conocenos'><img className={styles.icon} src='/img/conocenos.png' alt="Conócenos"/>CONÓCENOS</NavLink></li>
                    </ul>
                    <img onClick={() => setIsOpened(false)} id={styles.closeSidebar} src='/img/closeSidebar.svg' alt="Close Sidebar"></img>
                </nav>
                <div className={styles.navBarDots} onClick={() => setIsOpened(true)}>
                    <div style={{ backgroundColor: black ? 'black' : 'white' }}></div>
                    <div style={{ backgroundColor: black ? 'black' : 'white' }}></div>
                    <div style={{ backgroundColor: black ? 'black' : 'white' }}></div>
                </div>
                <div></div>
            </div>
            <div className={styles.contact}>
                <ul>
                    <li><a href='https://www.instagram.com/deepia.computer/' target='_blank' rel="noopener noreferrer"><img src='/img/instagram.svg' alt="Instagram"/></a></li>
                    <li><a href='https://www.tiktok.com/@deepia.computer' target='_blank' rel="noopener noreferrer"><img src='/img/tiktok.svg' alt="TikTok"/></a></li>
                    <li><a href='https://www.facebook.com/profile.php?id=100091871199438&mibextid=LQQJ4d' target='_blank' rel="noopener noreferrer"><img src='/img/facebook.svg' alt="Facebook"/></a></li>
                </ul>
                <a className={styles.whatsAppButton} href='https://wa.me/+523322792751?text=¡Hola!%20Me%20gustaría%20obtener%20más%20información%20sobre%20sus%20servicios.' target="_blank"><img src='/img/whatsapp.svg' alt="WhatsApp"></img></a>
            </div>
        </header>
    );
}

export default NavBar;